class Navbar {
  constructor () {
    this.body = document.body
    this.hamburgers = this.body.querySelectorAll('.nav-hamburger')
    this.menus = this.body.querySelectorAll('.nav-menu')
    this.toggleElements = this.body.querySelectorAll('.navbar-toggle')
    this.mobileSubmenuTogglers = this.body.querySelectorAll('.nav-slide .menu-item-has-children > a')
    this.mobileSubmenuBackButtons = this.body.querySelectorAll('.mobile-submenu__back')
    this.menuItemsWithSubMenus = this.body.querySelectorAll('.nav .menu-item-has-children')
    this.submenuRows = this.body.querySelectorAll('.h-submenu .row')

    this.initEvents()
  }

  toggleMenu () {
    // And toggle the active class
    this.body.classList.toggle('body--slide-active')
    Array.from(this.hamburgers).forEach(el => {
      el.classList.toggle('nav-hamburger--is-active')
    })

    Array.from(this.mobileSubmenuTogglers).forEach(el => {
      el.parentNode.classList.remove('is-active')
    })
  }

  activateMobileSubmenu (e) {
    e.preventDefault()

    const item = e.target.parentNode
    item.classList.add('is-active')
  }

  deactivateMobileSubmenu (e) {
    e.preventDefault

    const item = e.target.parentNode.parentNode
    item.classList.remove('is-active')
  }

  activateDesktopSubMenu () {
    this.body.classList.add('submenu-hover')
  }

  hideSubmenu () {
    this.body.classList.remove('submenu-hover')
    this.body.classList.add('submenu-out')

    setTimeout(() => {
      this.body.classList.remove('submenu-out')
    }, 500)
  }

  deActivateDesktopSubMenu (e) {
    const goingTo = e.relatedTarget|| e.toElement

    if (!goingTo) {
      this.hideSubmenu()

      return
    }

    const goingToParent = goingTo.parentElement

    if (goingToParent.classList.contains('menu-item-has-children')) {
      return
    }

    this.hideSubmenu()
  }

  initEvents () {
    const toggleHandler = () => this.toggleMenu()
    Array.from(this.toggleElements).forEach(el => {
      el.addEventListener('click', toggleHandler)
    })

    const submenuClickHandler = e => this.activateMobileSubmenu(e)
    Array.from(this.mobileSubmenuTogglers).forEach(el => {
      el.addEventListener('click', submenuClickHandler)
    })

    const submenuBackClickHandler = e => this.deactivateMobileSubmenu(e)
    Array.from(this.mobileSubmenuBackButtons).forEach(el => {
      el.addEventListener('click', submenuBackClickHandler)
    })

    Array.from(this.menuItemsWithSubMenus).forEach(el => {
      el.addEventListener('mouseenter', this.activateDesktopSubMenu.bind(this))
    })

    Array.from(this.menus).forEach(el => {
      el.classList.add('menu-initialised')

      el.addEventListener('mouseenter', () => {
        this.body.classList.add('submenu-in')

        setTimeout(() => {
          this.body.classList.remove('submenu-in')
        }, 500)
      })
    })

    const menuLeaveHandler = e => this.deActivateDesktopSubMenu(e)
    Array.from(this.menus).forEach(el => {
      el.addEventListener('mouseleave', menuLeaveHandler)
    })

    Array.from(this.submenuRows).forEach(el => {
      el.addEventListener('mouseleave', menuLeaveHandler)
    })

    // Array.from(this.submenuRows).forEach(el => {
    //   el.addEventListener('mouseleave', e => {
    //     this.body.classList.remove('submenu-hover')
    //   })
    // })
  }
}

export default Navbar
