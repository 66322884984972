class ContentTabs {
  constructor () {
    this.buttons = document.querySelectorAll('[data-tab-state][role="tab"]')
    this.panels = document.querySelectorAll('[data-tab-state][role="tabpanel"]')

    this.initEvents()
  }

  initEvents () {
    const buttons = Array.from(this.buttons)
    const panels = Array.from(this.panels)

    buttons.forEach(button => {
      button.addEventListener('click', e => {
        const tabToShow = button.id

        buttons.forEach(b => {
          const isActive = b.id === tabToShow
          b.setAttribute('data-tab-state', isActive ? 'selected' : '')
          b.setAttribute('aria-selected', isActive ? 'true' : 'false')
          b.setAttribute('tabindex', isActive ? '0' : '-1')
        })

        panels.forEach(p => {
          const label = p.getAttribute('aria-labelledby')
          const isActive = label === tabToShow
          p.setAttribute('data-tab-state', isActive ? 'selected' : '')
          p.hidden = !isActive
        })
      })
    })
  }
}

export default ContentTabs