class RatesSwitch {
  constructor (el) {
    this.el = el
    this.buttons = this.el.querySelectorAll('[data-switch-button]')
    this.rows = this.el.querySelectorAll('[data-switch-row]')

    this.initEvents()
  }

  clickSwitch (current) {
    const newCurrent = +current

    // switch active button
    Array.from(this.buttons).forEach((button, index) => {
      const isActive = button.classList.contains('active')
      if (isActive) {
        button.classList.remove('active')
      }

      if (index === (newCurrent - 1)) {
        button.classList.add('active')
      }
    })

    // switch active row
    Array.from(this.rows).forEach(row => {
      const tds = row.querySelectorAll('td')
      Array.from(tds).forEach((td, index) => {
        const isActive = td.classList.contains('active')
        if (isActive) {
          td.classList.remove('active')
        }

        if (index === newCurrent) {
          td.classList.add('active')
        }
      })
    })
  }

  initEvents () {
    Array.from(this.buttons).forEach(button => {
      const current = button.getAttribute('data-switch-button')
      button.addEventListener('click', this.clickSwitch.bind(this, current))
    })
  }
}

export default RatesSwitch
