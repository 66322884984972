function isVisible (elem) {
  return !(elem.offsetParent === null)
}

class Tabs {
  constructor () {
    this.tabs = document.querySelectorAll('[data-tab]')
    this.buttons = document.querySelectorAll('[data-button]')
    this.progress = document.querySelector('.progress-bar__progress')
    this.form = document.querySelector('form.list-form')
    this.currentTab = 0
    
    this.initEvents()
  }

  getStartingTab () {
    this.currentTabEl = document.querySelector('input[name="currentTab"]')
    if (this.currentTabEl) {
      this.showTab(this.currentTabEl.value)
    }
  }

  showTab (curr) {
    const currArr = curr.split(',')

    this.activeTabs = []

    Array.from(this.tabs).forEach(el => {
      const tabIndex = el.getAttribute('data-tab')
      const indexesArr = tabIndex.split(',')
      let hide = true

      indexesArr.forEach(index => {
        if (currArr.includes(index)) {
          hide = false

          this.activeTabs.push(el)
        }
      })

      el.hidden = hide
    })

    const lastItemOfCurrentlyActiveTabs = currArr[currArr.length - 1]
    if (this.progress) {
      const BASE_PROGRESS = 100
      const STEPS_AMOUNT = 3
      const currProgress = (lastItemOfCurrentlyActiveTabs * (BASE_PROGRESS / STEPS_AMOUNT)) - BASE_PROGRESS
      this.progress.style.transform = 'translateX(' + currProgress + '%)'
    }
  }

  checkErrorsIn (spot) {
    let hasErrors = false

    // number and tel validation
    Array.from(spot.querySelectorAll('input[type="number"].check-for-errors, input[type="tel"].check-for-errors')).forEach(inp => {
      const visible = isVisible(inp)

      if (!visible) {
        return
      }

      const value = inp.value.replace(/[^\d]/g, '')
      const error = value === ''

      if (error) {
        hasErrors = true
      }

      inp.parentNode.classList.toggle('has-error', error)
    })

    // text and email validation
    Array.from(spot.querySelectorAll('input[type="text"].check-for-errors, input[type="email"].check-for-errors')).forEach(inp => {
      const visible = isVisible(inp)

      if (!visible) {
        return
      }

      const error = inp.value === ''

      if (error) {
        hasErrors = true
      }

      inp.parentNode.classList.toggle('has-error', error)
    })

    return hasErrors
  }

  checkErrors () {
    let hasErrors = false

    if (this.activeTabs && this.activeTabs.length) {
      this.activeTabs.forEach(tab => {
        const errorInTab = this.checkErrorsIn(tab)

        if (errorInTab) {
          hasErrors = true
        }
      })
    } else if (this.form) {
      const errorInForm = this.checkErrorsIn(this.form)

      if (errorInForm) {
        hasErrors = true
      }
    }

    return hasErrors
  }

  initEvents () {
    this.getStartingTab()

    // if (this.form) {
    //   this.form.addEventListener('submit', e => {
    //     const hasErrors = this.checkErrors()
        
    //     if (hasErrors) {
    //       e.preventDefault()
    //     }
    //   })
    // }

    Array.from(this.buttons).forEach(button => {
      button.addEventListener('click', e => {
        e.stopPropagation()
        e.preventDefault()
        const next = button.getAttribute('data-button')
        const hasErrors = this.checkErrors()

        const radio = button.querySelector('input[type="radio"]')

        if (radio) {
          radio.checked = true
        }

        if (!hasErrors) {
          this.showTab(next)
          window.scrollTo(0, 0)
        }
      })
    })
  }
}

export default Tabs
