class Toggler {
  constructor (el) {
    this.el = el
    this.current = el.getAttribute('data-toggle')
    this.toggled = document.querySelector('[data-toggled="' + this.current + '"]')

    this.initEvents()
  }

  initEvents () {
    this.el.addEventListener('change', () => {
      const active = this.el.checked

      this.toggled.hidden = !active
    })
  }
}

export default Toggler
