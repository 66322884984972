import EmblaCarousel from 'embla-carousel'

class ModalSlider {
  constructor (el) {
    this.el = el
    this.options = {
      draggable: true,
      loop: true,
      skipSnaps: false,
      slidesToScroll: 1,
      containScroll: ''
    }
    this.viewport = this.el.querySelector('[data-slider-viewport]')
    this.prevButton = this.el.querySelector('[data-slider-prev]')
    this.nextButton = this.el.querySelector('[data-slider-next]')
    this.currentEl = this.el.querySelector('[data-slider-current]')
    this.totalEl = this.el.querySelector('[data-slider-total]')

    // modal
    this.modal = document.querySelector('[data-modal]')
    this.openModalButton = document.querySelector('[data-modal-open]')
    this.closeModalButton = document.querySelector('[data-modal-close]')

    this.initModalEvents()
    this.initSlider()
  }

  setupPrevNextBtns () {
    this.prevButton.addEventListener('click', this.embla.scrollPrev.bind(this))
    this.nextButton.addEventListener('click', this.embla.scrollNext.bind(this))
  }

  setCurrentNum () {
    this.currentEl.innerHTML = this.embla.selectedScrollSnap() + 1
  }

  setTotalNum () {
    this.totalEl.innerHTML = this.embla.slideNodes().length
  }

  openModal () {
    this.modal.setAttribute('aria-modal', 'true')
    this.modal.style.display = 'block'
    setTimeout(() => {
      this.modal.classList.add('is-shown')      
    }, 33)
    document.body.style.overflow = 'hidden'
    this.initSliderEvents()
  }

  closeModal () {
    this.modal.classList.remove('is-shown')
    setTimeout(() => {
      this.modal.style.display = 'none'      
    }, 300)
    document.body.style.overflow = ''
    this.modal.removeAttribute('aria-modal')
    this.removeSliderEvents()
  }

  initModalEvents () {
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    if (this.openModalButton) {
      this.openModalButton.addEventListener('click', this.openModal)
    }
    if (this.closeModalButton) {
      this.closeModalButton.addEventListener('click', this.closeModal)
    }
  }

  initSlider () {
    this.setCurrentNum = this.setCurrentNum.bind(this)
    this.setupPrevNextBtns = this.setupPrevNextBtns.bind(this)
    this.setTotalNum = this.setTotalNum.bind(this)
  }

  initSliderEvents () {
    this.embla = EmblaCarousel(this.viewport, this.options)
    this.embla.on('select', this.setCurrentNum)
    this.embla.on('init', this.setupPrevNextBtns)
    if (this.totalEl) {
      this.embla.on('init', this.setTotalNum)
    }
    if (this.currentEl) {
      this.embla.on('init', this.setCurrentNum)
    }
  }

  removeSliderEvents() {
    this.embla.off('select', this.setCurrentNum)
    this.embla.off('init', this.setupPrevNextBtns)
    if (this.totalEl) {
      this.embla.off('init', this.setTotalNum)
    }
    if (this.currentEl) {
      this.embla.off('init', this.setCurrentNum)
    }
    this.embla.destroy()
  }
}

export default ModalSlider