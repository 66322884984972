class SlideMortgages {
  constructor (el) {
    this.el = el
    this.container = this.el.querySelector('[data-slide-mortgages-container]')
    this.showMore = this.el.querySelectorAll('[data-slide-mortgages-more]')
    this.showNext = this.el.querySelectorAll('[data-slide-mortgages-next]')

    if (!this.container) {
      return
    }

    this.initEvents()
  }

  initEvents () {
    this.container.classList.add('show-limited')

    Array.from(this.showMore).forEach(more => {
      more.addEventListener('click', () => {
        this.container.style.maxHeight = this.container.scrollHeight + 'px'
        this.container.classList.remove('show-limited')
        more.style.display = 'none'

        setTimeout(() => {
          this.container.style.maxHeight = ''
        }, 600)

        Array.from(this.showNext).forEach(next => {
          next.style.display = 'inline-block'
        })
      })
    })
  }
}

export default SlideMortgages
