class Tooltip {
  constructor (ttContainer) {
    this.container = ttContainer
    this.tooltip = this.container.querySelector('[data-tooltip-el]')
    this.trigger = this.container.querySelector('[data-tooltip-trigger]')

    this.initEvents()
  }

  showTooltip () {
    this.tooltip.style.display = 'block'
    this.tooltip.removeAttribute('aria-hidden')
  }

  hideTooltip () {
    this.tooltip.style.display = 'none'
    this.tooltip.setAttribute('aria-hidden', 'true')
  }

  initEvents () {
    this.showTooltip = this.showTooltip.bind(this)
    this.hideTooltip = this.hideTooltip.bind(this)

    this.container.addEventListener('mouseenter', this.showTooltip)
    this.trigger.addEventListener('focus', this.showTooltip)
    this.container.addEventListener('mouseleave', this.hideTooltip)
    if (this.trigger) {
      this.trigger.addEventListener('blur', this.hideTooltip)
      this.trigger.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
          this.hideTooltip()
        }
      })
    }
  }
}

export default Tooltip
