class ContentOverflow {
  constructor (el) {
    this.el = el
    this.button = this.el.querySelector('[data-content-overflow-button]')
    this.active = false

    this.initEvents()
  }

  toggle () {
    this.active = !this.active
    this.button.innerHTML = this.active ? 'Lees minder' : 'Lees meer'
    this.el.classList.toggle('is-active', this.active)
  }

  initEvents () {
    this.toggle = this.toggle.bind(this)
    this.button.addEventListener('click', this.toggle)
  }
}

export default ContentOverflow
