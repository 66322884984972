class Map {
  constructor (el) {
    this.el = el
    this.markers = el.querySelectorAll('[data-map-marker]')

    this.loadScript()
  }

  loadScript () {
    const script = document.createElement('script')  // create a script DOM node
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC_kr4T8KjJ9Xv81De5Nb0ZblnMozNTAYk'  // set its src to the provided URL
    script.onreadystatechange = this.initMap.bind(this)
    script.onload = this.initMap.bind(this)

    document.head.appendChild(script)  // add it to the end of the head section
  }

  initMap () {
    this.args = {
      zoom: 16,
      center: {
        lat: +this.markers[0].getAttribute('data-lat'),
        lng: +this.markers[0].getAttribute('data-lng')
      },
      mapTypeId: window.google.maps.MapTypeId.ROADMAP
    }

    this.map = new window.google.maps.Map(this.el, this.args)
    this.map.markers = []

    for (let i = 0; i < this.markers.length; i++) {
      this.addMarker(this.markers[i])
    }
  }

  addMarker (marker) {
    const lat = marker.getAttribute('data-lat')
    const lng = marker.getAttribute('data-lng')
    const latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    }

    const mapMarker = new window.google.maps.Marker({
      position: latLng,
      map: this.map,
      icon: {
        path: 'M18.5 0A18.5 18.5 0 0 0 0 18.5c0 12.48 18.343 26.781 18.5 26.781.382 0 18.5-14.166 18.5-26.781A18.5 18.5 0 0 0 18.5 0zm0 22.32a3.477 3.477 0 1 1 3.477-3.476A3.477 3.477 0 0 1 18.5 22.32z',
        fillColor: '#1051ff',
        fillOpacity: 1,
        strokeColor: 'transparent',
        anchor: new google.maps.Point(
          16, // width
          39 // height
        ),
      }
    })

    window.google.maps.event.addListener(mapMarker, 'click', function() {
      window.open(marker.getAttribute('data-url'), '_blank').focus()
    })

    this.map.markers.push(mapMarker)
  }
}

export default Map
